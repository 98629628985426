import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  sidebarShow: true,
  asideShow: false,
  sidebarUnfoldable: false,
  theme: 'default',
  isLoading: false,
  siteSettings: {},
}
const appSettings = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    showLoading: (state, action) => {
      state.isLoading = action.payload // Ensure action.payload is a boolean when dispatching
    },
    setSidebarShow: (state, action) => {
      return {
        ...state,
        sidebarShow: action.payload,
      }
    },
    setAsideShow: (state, action) => {
      return {
        ...state,
        asideShow: action.payload,
      }
    },
    setSidebarUnfoldable: (state, action) => {
      return {
        ...state,
        sidebarUnfoldable: action.payload,
      }
    },

    setTheme: (state, action) => {
      return {
        ...state,
        theme: action.payload,
      }
    },
    setSiteSettings: (state, action) => {
      return {
        ...state,
        siteSettings: action.payload,
      }
    },
  },
})

export const {
  setSidebarShow,
  setAsideShow,
  setTheme,
  setSidebarUnfoldable,
  showLoading,
  setSiteSettings,
} = appSettings.actions

export default appSettings.reducer
