import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setCookie } from 'react-use-cookie'
import { JWT_COOKIE } from 'src/helpers/cookies'

const initialState = {
  token: null,
  user: null,
  provider: null,
  plan: null,
  permission: [],
  isReady: false,
}
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      // set new cookie
      return {
        ...state,
        ...action.payload,
      }
    },
    logOut: (state, action) => {
      // remove cookie
      setCookie(JWT_COOKIE, '')
      return {
        ...state,
        ...initialState,
      }
    },
  },
})

export const { login, logOut } = authSlice.actions

export default authSlice.reducer
