import React from 'react'
import { useSelector } from 'react-redux'
import { CSpinner } from '@coreui/react-pro'

const LoadingPage = () => {
  const isLoading = useSelector((state) => state.appSettings.isLoading)
  if (!isLoading) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent grey
        color: '#ccc',
        fontSize: '16px', // smaller font size
        zIndex: 9999, // Ensure it covers other components
        fontFamily: 'Arial, sans-serif', // more universal font
        textAlign: 'center', // ensures text is centered
      }}
    >
      <CSpinner color="primary" variant="grow" />
      <span>
        🌀 Hang tight!
        <br />
        We&apos;re on a quest to fetch your request!
        <br />
        ⏳🚀 #LoadingMagic 🎩✨
      </span>
    </div>
  )
}

export default LoadingPage
